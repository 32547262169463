// var stickyHeader = false;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = true;
var countUpStats = true;
var countUpStatsDuration = 3000;

var subsiteHijackMainNav = ['careers-guidance-for-you', 'bucks-employment-support'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

if ($('body.subsite').hasClass('bucks-employment-support')) {
  $('<div class="subsitePreFooter"><p>This project is funded by the UK government through the UK Shared Prosperity Fund, administered by Buckinghamshire Council.</p><p>The UK Shared Prosperity Fund is a central pillar of the UK government’s Levelling Up agenda and provides £2.6 billion of funding for local investment by March 2025. The Fund aims to improve pride in place and increase life chances across the UK investing in communities and place, supporting local business, and people and skills. For more information, visit <a href="https://www.gov.uk/government/publications/uk-shared-prosperity-fundprospectus">www.gov.uk/government/publications/uk-shared-prosperity-fundprospectus</a></p></div>').prependTo('.subsite .pageFooterWrapper');
  $('<div class="bucksSubsiteLogos"><img src="/fresco/clients/advizaconnexionsbuckinghamshir/assets/funder-logo1.png" class="funder-logo1" /><img src="/fresco/clients/advizaconnexionsbuckinghamshir/assets/funder-logo2.png" /><a href="https://www.buildingfuturesbucks.org.uk/" targe="_blank"><img src="/fresco/clients/advizaconnexionsbuckinghamshir/assets/programme-logo.png" /></a></div>').insertAfter(".mainLogo");
  $(".cta-button.persist").removeClass("persist");
  $("a[href='/contact']").attr('href', 'mailto:info@buildingfuturesbucks.co.uk')
}